import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import React, { AnchorHTMLAttributes, HTMLAttributes } from "react";
import styled from "styled-components";
import { ProjectPage } from "../../components/ProjectPage";
import ZoomableImage from "../../components/shared/ZoomableImage";
import { onDesktop, onLargeDesktop } from "../../shared/layout/Breakpoints";

const Underline = styled.hr``;
const TEXT_COLOR = "tomato";
const EtsyLink = styled(function({
  children,
  ...props
}: AnchorHTMLAttributes<HTMLAnchorElement>) {
  return (
    <a target="_blank" rel="noopener" {...props}>
      <div>{children}</div>
      <Underline />
    </a>
  );
})`
  display: inline-block;
  padding: 0 0 0.33em;
  font-family: serif;
  text-decoration: none;
  font-style: italic;
  font-size: 1.75em;
  text-decoration: none;
  border: none;

  &,
  &:hover,
  &:active,
  &:visited {
    color: ${TEXT_COLOR};
  }

  ${Underline} {
    margin: 0;
    width: 100%;
    height: 2px;
    display: block;
    background: ${TEXT_COLOR};
    transition: transform 0.15s ease;
    transform: scaleX(0);
  }

  &:hover ${Underline}, &:active ${Underline} {
    transform: scaleX(1);
  }
`;

interface ProductTitleProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  title: string;
  description: string;
}

const ProductTitle = styled(function({ title, description, ...props }) {
  return (
    <div {...props}>
      <h1>{title}</h1>
      <p>{description}</p>
    </div>
  );
})`
  h1,
  p {
    margin: 0;
  }

  margin-bottom: 1em;
`;

const CatrinaImage = styled(ZoomableImage)`
  max-width: 480px;
  margin: 0 auto 1em;
  ${onDesktop`
    width: 320px;
    margin: 1em 0;
  `}
  ${onLargeDesktop`
    width: 480px;
  `}
  display: block;
`;

const Gallery = styled.aside`
  flex: 0;
`;
const Body = styled.section`
  flex: 1 0;
  ${onDesktop`
    margin-left: 2em;
    padding-top: 4em;
  `}
  ${onLargeDesktop`
    margin-left: 4em;
  `}

  p {
    max-width: 40em;
  }
`;
const ShopPage = styled.div`
  display: flex;
  flex-direction: column;
  ${onDesktop`
    flex-direction: row;
  `}
`;

export default function CatrinaPage() {
  const imags = useStaticQuery(graphql`
    query {
      catrina_portrait: file(
        relativePath: { eq: "work/catrina_portrait_skewed.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      catrina_lino: file(relativePath: { eq: "work/catrina_lino.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <ProjectPage name="Catrina">
      <ShopPage>
        <Gallery>
          <CatrinaImage
            alt="Catrina"
            fluid={imags.catrina_portrait.childImageSharp.fluid}
          />
        </Gallery>
        <Body>
          <ProductTitle
            title="La Catrina"
            description="Linocut print on paper with metallic ink."
          />
          <hr />
          <p>
            La Catrina, or «The fancy lady», is a skeleton wearing a fancy hat
            with a feather and fancy clothes. Initially a symbol made to mock
            indigenous people in Mexico, today it has been reclaimed by mexicans
            to represent the day of the dead, a pre-hispanic celebration that
            has lasted until today, absorbing also catholic traditions. Day of
            the dead is 1st and 2nd of November in Mexico.
          </p>
          <p>
            This drawing is my interpretation of La Catrina, with gold and
            copper tones to reflect the candle lights put at night. The frame
            represents elements of the offering altars put to remember dead
            family and friends. This is a very important Mexican tradition, and
            it helps us to remember people who are no longer with us. The dog,
            or Xolotl, is a reference to the Aztec dog god, who guides the dead
            to the afterlife.
          </p>
          <p>
            The bronze color reminds of the warm light of candles in the night,
            when offerings are laid to remember our dead ones.
          </p>
          <p>
            This is a relief print in which a piece of linoleum is carved out to
            produce a printing block. Ink is added to the printing block, and
            transferred to paper using a printer.
          </p>
        </Body>
      </ShopPage>
      <LinoImage
        alt="Linocut"
        fluid={imags.catrina_lino.childImageSharp.fluid}
      />
      <p>
        <em>Original linolium carving used to make handmade prints.</em>
      </p>
    </ProjectPage>
  );
}

const LinoImage = styled(ZoomableImage)`
  max-width: 720px;
`;
